<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <transition name="slide-fade">
                    <div v-if="show">
                        <p>{{ $t('Public.CompanyDynamic.banner.title') }}</p>
                        <!-- <div>{{ $t('Public.CompanyDynamic.banner.text') }}</div> -->
                    </div>
                </transition>
            </div>
            <ul class="dynamic" :class="{ dynamic_en: $i18n.locale == 'EN' }">
                <li v-for="(item, index) in $t('Public.CompanyDynamic.sigrealNews')" :key="index">
                    <div :class="$i18n.locale == 'CN' ? 'dynamic_date' : 'dynamic_date dynamic_dates'">
                        <p>{{ item.date1 }}</p>
                        <!-- <span>{{item.date2}}</span> -->
                    </div>
                    <div :class="$i18n.locale == 'CN' ? 'dynamic_text' : 'dynamic_text dynamic_texts'">
                        <p :class="$i18n.locale == 'CN' ? '' : 'dynamic_text_p'">{{ item.title }}</p>
                        <div :class="$i18n.locale == 'CN' ? '' : 'dynamic_text_div'">{{ item.text }}</div>
                    </div>
                    <div class="dynamic_img"><img :src="require('../../public/images/dynamic/' + item.src)" /></div>
                </li>
            </ul>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'CompanyDynamic',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            show: false
        };
    },
    methods: {},
    mounted() {
        this.show = true;
    }
};
</script>
<style scoped>
.banner {
    width: 100%;
    height: 3.76rem;
    padding: 2rem 2.6rem 0 3.6rem;
    font-size: 0.22rem;
    font-weight: 300;
    background: url(../../public/images/dynamic/banner.jpg) no-repeat;
    background-size: 100% 100%;
    line-height: 2;
    background-color: #396789;
}
.banner p {
    font-size: 0.54rem;
    line-height: 1.39;
    margin-bottom: 0.18rem;
}
.dynamic {
    padding: 0.8rem 3.6rem 1.2rem;
    color: #333;
}
.dynamic li {
    width: 100%;
    padding: 0.2rem 0;
    display: flex;
    border-bottom: 1px solid #dbdbdb;
    /* margin-bottom:0.2rem; */
    padding-top: 0;
}
.dynamic li:last-child {
    border-bottom: 0;
}
.dynamic_date {
    width: 1.66rem;
    padding-top: 0.27rem;
    line-height: 2;
}
.dynamic_dates {
    padding-top: 0.27rem !important;
}
.dynamic_text {
    width: 6.3rem;
    padding-top: 0.27rem;
    line-height: 2;
    /* text-align: justify; */
    text-align: left;
}
.dynamic_texts {
    padding-top: 0.27rem !important;
}

.dynamic_text_div {
    /* line-height: 0.32rem; */
    text-align: justify;
}
.dynamic_text_p {
    /* line-height: 0.32rem !important;
    margin-bottom: 0.1rem !important; */
}
.dynamic_en .dynamic_text,
.dynamic_en .dynamic_date {
    padding-top: 0;
    text-align: left;
}
.dynamic_date p,
.dynamic_text p {
    width: 100%;
    font-size: 0.24rem;
    font-weight: 600;
    color: #333333;
    line-height: 1.375;
    margin-bottom: 0.06rem;
}
.dynamic_text p {
    margin-bottom: 0.15rem;
}
.dynamic_img {
    margin-left: 0.45rem;
    margin-top: 0.27rem;
    width: 3.6rem;
    height: 2.4rem;
    overflow: hidden;
}
.dynamic_img img {
    cursor: pointer;
    transition: all 0.8s;
}
.dynamic_img img:hover {
    transform: scale(1.3);
}
</style>
